import * as React from "react";
import { useReducer, StrictMode, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "./context";
import { Game } from "./game";
import * as DesignWeb from "./design-web";
import * as DesignR3f from "./design-r3f";

function App() {
  const [key, reset] = useReducer((state: number) => state + 1, 0);
  const [r3f, toggleR3f] = useReducer((state: boolean) => !state, false);
  return (
    <>
      <button
        style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
        onClick={toggleR3f}
      >
        3
      </button>
      <Provider value={r3f ? DesignR3f : DesignWeb}>
        <Game reset={reset} key={key} />
      </Provider>
    </>
  );
}
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);
