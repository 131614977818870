import * as React from "react";
import { Container } from "./types-props";

export function LayoutMagic({ children }: { children: Container["children"] }) {
  return (
    <div
      style={{
        display: "flex",
        alignSelf: "stretch",
        justifyContent: "center",
        padding: "0 calc(50vw - 50vh + 100px)",
      }}
    >
      <div
        style={{
          position: "relative",
          flexGrow: 1,
          maxWidth: 1024,
          aspectRatio: "1/1",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
}
