import { useEffect, useLayoutEffect, useRef } from "react";
import { useInput } from "./context";

function rAF(cb: () => void) {
  if (typeof requestAnimationFrame !== "undefined") {
    const handle = requestAnimationFrame(cb);
    return () => cancelAnimationFrame(handle);
  }
  const handle = setTimeout(cb, 0);
  return () => clearTimeout(handle);
}

export function useCallAt(at: number | null, callback: () => void) {
  const ref = useLast(callback);
  useEffect(() => {
    if (!at) return;

    let clearRaf: ReturnType<typeof rAF> | null = null;
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const now = Date.now();
    let slip = 1 / 30;
    if (now + slip > at) {
      handler();
    } else {
      timeout = setTimeout(handler, at - now - slip);
    }

    return function cleanup() {
      clearRaf?.();
      if (timeout) clearTimeout(timeout);
    };

    function handler() {
      if (!at) return;
      timeout = null;
      clearRaf = null;
      if (Date.now() >= at) {
        ref.current();
      } else {
        clearRaf = rAF(handler);
      }
    }
  }, [at, ref]);
}

export function useKeyEvent(
  events: {
    [key: string]: () => void;
  },
  type: "keydown" | "keyup" | "keypress" = "keydown"
) {
  const handlers = useLast(events);
  useEffect(() => {
    function handler(event: KeyboardEvent) {
      handlers.current[event.key]?.();
    }
    if (typeof document !== "undefined") {
      document.body.addEventListener(type, handler);
      return () => {
        document.body.removeEventListener(type, handler);
      };
    }
  }, [handlers, type]);
  useInput((input: string) => {
    handlers.current[input]?.();
  });
}

function useLast<T>(val: T) {
  const ref = useRef(val);
  useLayoutEffect(() => {
    ref.current = val;
  });
  return ref;
}

export function wrap(val: number, size: number, overflowMax: boolean = true) {
  const offset = overflowMax ? -0.5 : 0.5;
  val = ((val + offset) % size) - offset;
  if (val < -offset) val += size;
  return val;
}
