import * as React from "react";
import { memo } from "react";
import { animated, useSpring } from "react-spring";
import type * as Props from "./types-props";
import { wrap } from "./game-utils";
import { LayoutMagic } from "./layout-magic";
import { Canvas } from "@react-three/fiber";

export const animation: boolean = false;

export const Board = memo<Props.Board>(({ width, height }): JSX.Element => {
  return (
    <>
      <pointLight position={[10, 10, 10]} />
    </>
  );
});

export function Container({ children }: Props.Container): JSX.Element {
  return (
    <LayoutMagic>
      <Canvas camera={{ fov: 75, near: 0.1, far: 1000, position: [0, 0, 5] }}>
        {children}
      </Canvas>
    </LayoutMagic>
  );
}

export function Piece({
  x,
  y,
  board,
  color = "hotpink",
}: Props.Piece): JSX.Element {
  return (
    <mesh
      position={[
        wrap(x, board.width) - board.width / 2,
        -wrap(y, board.height) + board.height / 2,
        -5,
      ]}
      scale={0.5}
    >
      <sphereBufferGeometry />
      <meshStandardMaterial color={color} />
    </mesh>
  );
}
