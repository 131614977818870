import React from "react";
import { View, Text, Pressable } from "./context";
import { Children } from "./types-props";

export function Results({
  score,
  reset,
}: {
  score: number;
  reset: () => void;
}) {
  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          backgroundColor: "white",
          padding: 10,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "black",
        }}
      >
        <Text style={{ fontFamily: "sans-serif", fontSize: 30 }}>
          SCORE: {score + ""}
        </Text>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Pressable
            style={{ paddingVertical: 4, paddingHorizontal: 8 }}
            onPress={reset}
          >
            <Text
              style={{
                fontSize: 16,
                backgroundColor: "lightgray",
                paddingVertical: 4,
                paddingHorizontal: 8,
              }}
            >
              Play again
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

export function Buttons({
  handlers,
}: {
  handlers: { [key in `Arrow${"Up" | "Left" | "Down" | "Right"}`]: () => void };
}) {
  return (
    <View style={{ flexDirection: "column", alignItems: "center" }}>
      <Button onClick={handlers.ArrowUp}>Up</Button>
      <View style={{ flexDirection: "row" }}>
        <Button onClick={handlers.ArrowLeft}>Left</Button>
        <Button onClick={handlers.ArrowDown}>Down</Button>
        <Button onClick={handlers.ArrowRight}>Right</Button>
      </View>
    </View>
  );
}

function Button({
  children,
  onClick,
}: {
  children: string;
  onClick: () => void;
}) {
  return (
    <Pressable onPress={onClick}>
      <View
        style={{
          backgroundColor: "lightgray",
          margin: 10,
          width: 80,
          height: 80,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: 16 }}>{children}</Text>
      </View>
    </Pressable>
  );
}

export function CenteredFlexColumn({ children }: { children: Children }) {
  return (
    <View
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      {children}
    </View>
  );
}
