import * as React from "react";
import { memo } from "react";
import { useSpring, animated } from "react-spring";
import type * as Props from "./types-props";
import { wrap } from "./game-utils";
import { LayoutMagic } from "./layout-magic";

export const animation: boolean = true;

export const Board = memo<Props.Board>(({ width, height }): JSX.Element => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${width}, 1fr)`,
        gridTemplateRows: `repeat(${width}, 1fr)`,
        width: "100%",
        height: "100%",
        boxSizing: "border-box",

        borderColor: "black",
        borderStyle: "solid",
        borderWidth: 0,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
      }}
    >
      {Array.from({ length: height * width }).map((_, i) => (
        <div
          key={i}
          style={{
            borderColor: "black",
            borderStyle: "solid",
            borderWidth: 0,
            borderTopWidth: 1,
            borderRightWidth: 1,
          }}
        />
      ))}
    </div>
  );
});

export function Container({ children }: Props.Container): JSX.Element {
  return <LayoutMagic>{children}</LayoutMagic>;
}

export function Piece({
  x,
  y,
  board,
  color = "black",
}: Props.Piece): JSX.Element {
  const style = useSpring({
    to: { x, y },
    config: {
      friction: 10,
      damping: 100,
      mass: 0.2,
    },
  });
  const css = {
    background: color,
    border: "1px solid black",
    margin: `calc(${10 / board.width}%)`,
    width: `calc(${70 / board.width}%)`,
    height: `calc(${70 / board.width}%)`,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "stretch",
    position: "absolute",
    boxSizing: "border-box",
    borderRadius: "50%",
  } as const;
  return (
    <>
      <animated.div
        style={{
          ...css,
          left: style.x.to(
            (v) => (wrap(v, board.width, false) / board.width) * 100 + "%"
          ),
          top: style.y.to(
            (v) => (wrap(v, board.height, false) / board.width) * 100 + "%"
          ),
        }}
      />
      <animated.div
        style={{
          ...css,
          left: style.x.to(
            (v) => (wrap(v, board.width) / board.width) * 100 + "%"
          ),
          top: style.y.to(
            (v) => (wrap(v, board.height) / board.width) * 100 + "%"
          ),
        }}
      />
    </>
  );
}
