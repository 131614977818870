import * as React from "react";
import { createContext, useContext, useMemo, useState } from "react";
import type * as Design from "./design-r3f";
import type * as Props from "./types-props";
import type * as RN from "react-native";
import { useEffect } from "react";

export const context = createContext<typeof Design>(null!);

export function Provider(props: {
  value: typeof Design;
  children: Props.Children;
}) {
  const [rn, setRn] = useState<typeof import("react-native") | null>(null);
  useEffect(() => {
    import("react-native").then(
      (v) => setRn(v),
      () => {}
    );
  }, []);
  const value = useMemo(
    () => ({ ...props.value, parts: (props.value as any).parts || rn }),
    [rn, props.value]
  );
  if (!value.parts) return null;
  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function useAnimation() {
  return useContext(context).animation;
}

export function Board(props: Props.Board) {
  const C = useContext(context);
  return <C.Board {...props} />;
}

export function Container(props: Props.Container) {
  const C = useContext(context);
  return <C.Container {...props} />;
}

export function Piece(props: Props.Piece) {
  const C = useContext(context);
  return <C.Piece {...props} />;
}

export function View(props: RN.ViewProps & { children?: Props.Children }) {
  const parts = (useContext(context) as any).parts;
  const C = parts.View || parts.Box;
  return <C {...props} />;
}

export function Text(
  props: RN.TextProps & { children?: Props.Children<string | number> }
) {
  const C = (useContext(context) as any).parts?.Text;
  return <C {...props} />;
}

export function Pressable(props: RN.PressableProps) {
  const parts = (useContext(context) as any).parts;
  const C = parts?.Pressable;
  if (!C) return null;
  return <C {...props} />;
}

export function HUD(props: Props.HUD) {
  const C = useContext(context) as any;
  if (!("HUD" in C)) return null;
  return <C.HUD {...props} />;
}

export function useInput(arg: any) {
  const c = useContext(context) as any;
  c.parts?.useInput?.(arg);
}
