import { useReducer } from "react";

export function calcNextState(state: Snake): Snake {
  if (state.status === "game-over") return state;
  return reduceFood(reduceMove(reduceDirection(state)));
}

function snakeReducer(
  state: Snake,
  action:
    | { type: "next" | "unpause"; now: number }
    | { type: "set-direction"; direction: Snake["direction"] }
    | { type: "pause" }
): Snake {
  if (action.type === "next") {
    return reduceTick(action.now, calcNextState(state));
  }
  if (action.type === "set-direction") {
    if (
      state.directionQueue.length === 0 &&
      (state.direction.x === action.direction.x ||
        state.direction.y === action.direction.y)
    ) {
      return state;
    }
    return {
      ...state,
      directionQueue: state.directionQueue.concat(action.direction),
    };
  }
  if (action.type === "pause") {
    if (state.status !== "running") return state;
    return {
      ...state,
      status: "paused",
    };
  }
  if (action.type === "unpause") {
    if (state.status !== "paused") return state;
    return {
      ...state,
      status: "running",
      lastTick: action.now,
    };
  }
  throw new Error("unknown action");
}

function reduceTick(now: number, state: Snake): Snake {
  return {
    ...state,
    lastTick: now,
  };
}

function reduceMove(state: Snake): Snake {
  const head = state.pieces[0];
  const pieces = state.pieces.slice(0, state.snakeLength - 1);
  const nextHead = {
    bigX: head.bigX + state.direction.x,
    bigY: head.bigY + state.direction.y,
    x: wrap(head.x + state.direction.x, state.board.width),
    y: wrap(head.y + state.direction.y, state.board.height),
  };

  if (pieces.some((p) => p.y === nextHead.y && p.x === nextHead.x)) {
    if (state.status === "almost-crash") {
      return { ...state, status: "game-over" };
    }
    return {
      ...state,
      status: "almost-crash",
    };
  }
  return {
    ...state,
    pieces: [nextHead, ...pieces],
  };
}

function reduceDirection(state: Snake): Snake {
  return {
    ...state,
    direction: state.directionQueue[0] || state.direction,
    directionQueue: state.directionQueue.slice(1),
  };
}

function reduceFood(state: Snake) {
  const head = state.pieces[0];
  const eatenFood = state.food.findIndex(
    (f) => f.x === head.x && f.y === head.y
  );
  //console.log({ eatenFood });
  if (eatenFood >= 0) {
    return {
      ...state,
      food: [...state.food]
        .splice(eatenFood + 1, 1)
        .concat(genFood(state.board, state.pieces)),
      snakeLength: state.snakeLength + 1,
    };
  }
  return state;
}

function wrap(value: number, to: number) {
  while (value < 0) value += to;
  while (value >= to) value -= to;
  return value;
}

function genFood(
  board: { width: number; height: number },
  pieces: readonly { x: number; y: number }[]
) {
  while (true) {
    const food = {
      x: Math.floor(Math.random() * board.width),
      y: Math.floor(Math.random() * board.height),
    };
    if (!pieces.some((p) => p.x === food.x && p.y === food.y)) {
      return food;
    }
  }
}

function getInitialState() {
  const board = { width: 10, height: 10 };
  const pieces = [
    {
      y: Math.floor(board.width / 2),
      x: Math.floor(board.height / 2),
      bigY: Math.floor(board.width / 2),
      bigX: Math.floor(board.height / 2),
    },
  ];
  const direction = { x: 1, y: 0 };
  return {
    pieces,
    direction,
    lastTick: Date.now(),
    board,
    snakeLength: 5,
    status: "running" as "almost-crash" | "running" | "game-over" | "paused",
    food: [genFood(board, pieces)],
    directionQueue: [] as readonly typeof direction[],
  };
}
export type Snake = ReturnType<typeof getInitialState>;

export function useSnake() {
  return useReducer(snakeReducer, null, getInitialState);
}
