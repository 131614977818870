import * as React from "react";
import { calcNextState, useSnake } from "./use-snake";
import { useCallAt, useKeyEvent } from "./game-utils";
import {
  Board,
  Container,
  context,
  Piece,
  Provider,
  useAnimation,
  HUD,
} from "./context";
import { useContext } from "react";
import { Buttons, CenteredFlexColumn, Results } from "./pre-ported";

const tickTime = 100;

export function Game({ reset }: { reset: () => void }) {
  const [snakeNow, dispatch] = useSnake();

  useCallAt(
    snakeNow.status === "game-over" || snakeNow.status === "paused"
      ? 0
      : snakeNow.lastTick + tickTime,
    () => {
      const now = Date.now();
      dispatch({
        type: "next",
        now: Math.max(snakeNow.lastTick + tickTime, now - 2 * tickTime),
      });
    }
  );

  const snakeFuture =
    !useAnimation() || snakeNow.status === "almost-crash"
      ? snakeNow
      : calcNextState(snakeNow);

  const handlers = {
    ArrowDown: () =>
      dispatch({ type: "set-direction", direction: { x: 0, y: 1 } }),
    ArrowUp: () =>
      dispatch({ type: "set-direction", direction: { x: 0, y: -1 } }),
    ArrowLeft: () =>
      dispatch({ type: "set-direction", direction: { x: -1, y: 0 } }),
    ArrowRight: () =>
      dispatch({ type: "set-direction", direction: { x: 1, y: 0 } }),
    " ": () => {
      if (snakeNow.status === "running") dispatch({ type: "pause" });
      else dispatch({ type: "unpause", now: Date.now() });
    },
  };
  useKeyEvent({
    ...handlers,
    s: handlers.ArrowDown,
    a: handlers.ArrowLeft,
    d: handlers.ArrowRight,
    w: handlers.ArrowUp,
  });

  const value = useContext(context);

  return (
    <CenteredFlexColumn>
      <Container {...snakeFuture.board}>
        <Provider value={value}>
          <Board {...snakeFuture.board} />
          {snakeNow.food.map((piece, i) => (
            <Piece
              key={i}
              x={piece.x}
              y={piece.y}
              board={snakeFuture.board}
              color="green"
            />
          ))}
          {snakeFuture.pieces.map((piece, i) => (
            <Piece
              key={i}
              x={piece.bigX}
              y={piece.bigY}
              board={snakeFuture.board}
            />
          ))}
          <HUD state={snakeNow} />
        </Provider>
      </Container>
      {snakeNow.status === "game-over" ? (
        <Results score={snakeNow.pieces.length} reset={reset} />
      ) : null}
      <Buttons handlers={handlers} />
    </CenteredFlexColumn>
  );
}
